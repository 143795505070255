import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c(VFadeTransition,[_c(VSkeletonLoader,{attrs:{"loading":_vm.loading,"type":"article","transition":"fade-transition"}},[_c('div',{staticClass:"ba-1 bg-database px-4 py-2 radius-1"},_vm._l((_vm.contractInfoItems),function(field,pos){return _c(VListItem,{key:pos,staticClass:"pl-0"},[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v(_vm._s(field.label)+" ")]),(field.type !== 'address')?_c(VListItemTitle,{staticClass:"body-2"},[_vm._v(" "+_vm._s(field.type === 'timestamp' ? _vm.helpers.formatDatetime(field.value) : field.value)+" ")]):_c(VListItemTitle,{staticClass:"body-2"},[_c('span',{directives:[{name:"past-html",rawName:"v-past-html",value:(_vm.helpers.shortcut(field.value, 12)),expression:"helpers.shortcut(field.value, 12)"}],staticClass:"mr-3"}),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c(VIcon,{staticClass:"text--secondary",attrs:{"small":""},on:{"click":function($event){return _vm.copyAddress(field.value)}}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy address")])])],1)],1)],1)}),1)])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"d-flex mb-4"},[_c('h3',{staticClass:"text--secondary font-weight-regular header-table"},[_vm._v("Constant Info")])])
}]

export { render, staticRenderFns }