import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{ref:"cmdLineDialog",attrs:{"width":"800","scrollable":"","retain-focus":false},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{attrs:{"flat":"","outlined":""}},[_c(VCardTitle,{staticClass:"sidebar d-flex justify-center py-2"},[_c('span',{staticClass:"body-1 font-weight-medium text-uppercase text--secondary"},[_vm._v("Tezos-client")]),_c(VSpacer),_c(VBtn,{staticClass:"mr-4 text--secondary",attrs:{"text":""},on:{"click":() => {
            _vm.copy(_vm.cmdLine)
          }}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-content-copy")]),_vm._v("Copy ")],1),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pt-4 pb-0"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"hash"},[_vm._v(_vm._s(_vm.cmdLine))])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VDivider)],1),_c(VCol,[_c('span',{staticClass:"overline"},[_vm._v("Optional settings")]),_c(VTextField,{staticClass:"mt-4",attrs:{"label":"Burn cap","type":"numeric","rules":_vm.rules,"dense":"","outlined":""},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.burnCap),callback:function ($$v) {_vm.burnCap=$$v},expression:"burnCap"}}),_c(VCheckbox,{attrs:{"color":"primary","label":"Dry run"},model:{value:(_vm.dryRun),callback:function ($$v) {_vm.dryRun=$$v},expression:"dryRun"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }