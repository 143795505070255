import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"d-flex"},[_c('h3',{staticClass:"text--secondary font-weight-regular header-table"},[_vm._v("Network Info")]),_c(VBtn,{staticClass:"text--secondary ml-auto",attrs:{"small":"","text":""},on:{"click":function($event){_vm.showRaw = true}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-code-json")]),_c('span',[_vm._v("Constants")])],1)],1),_c('div',{staticClass:"ba-1 bg-database px-4 py-2 radius-1",staticStyle:{"margin-top":"19px"}},_vm._l((_vm.networkObjInfo),function(field,pos){return _c(VListItem,{key:pos,staticClass:"pl-0"},[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v(_vm._s(field.name))]),_c(VListItemTitle,{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.state[field.key])+" ")])],1)],1)}),1),_c('RawJsonViewer',{attrs:{"type":"constants","network":_vm.network},model:{value:(_vm.showRaw),callback:function ($$v) {_vm.showRaw=$$v},expression:"showRaw"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }