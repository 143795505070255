import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VToolbar,{attrs:{"flat":"","color":"toolbar","height":"48"}},[_c(VTabs,{attrs:{"center-active":"","background-color":"transparent","slider-color":"primary"}},[_c(VTab,{key:0,staticStyle:{"width":"175px"},attrs:{"to":_vm.pushTo({ name: 'operations' }),"title":_vm.contract.operations_count,"replace":""}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("mdi-swap-horizontal")]),_vm._v("operations "),_c('span',{staticClass:"ml-1"},[_vm._v("("+_vm._s(_vm._f("numberToCompactSIFormat")(_vm.contract.operations_count || 0))+")")])],1),_vm._l((_vm.tabs),function(tab,idx){return _c(VTab,{key:idx + 1,attrs:{"to":tab.to}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(_vm._s(tab.icon))]),_vm._v(_vm._s(tab.text)+" ")],1)}),(_vm.isAnythingLoading)?_c(VTab,[_c(VSkeletonLoader,{attrs:{"loading":_vm.isAnythingLoading,"type":"button","transition":"fade-transition"}})],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }