import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height bg-canvas-base pr-4 pl-4"},[_c(VRow,[_c(VCol,{staticClass:"pl-0",attrs:{"cols":_vm.keyhash ? '12' : '8'}},[_c('router-view',{staticClass:"ml-0",attrs:{"address":_vm.address,"count":_vm.count},on:{"update:count":function($event){_vm.count=$event}}})],1),(!_vm.keyhash && _vm.actions)?_c(VCol,{attrs:{"cols":"4"}},[_c(VList,{staticClass:"bigmap-sidebar-list"},[_c(VListItem,{staticClass:"pr-0"},[_c('h2',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.keyhash ? _vm.shortcutOnly(_vm.keyhash) : `Big Map ${_vm.ptr}`)+" ")])]),_c(VListItem,{staticClass:"pr-0"},[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v("Status")]),_c(VListItemTitle,{staticClass:"caption text-uppercase"},[(_vm.removed)?_c('span',{staticClass:"error--text"},[_vm._v("Removed")]):_c('span',[_vm._v("Allocated")])])],1)],1),(!isNaN(_vm.totalBytes))?_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v("Total size")]),_c(VListItemTitle,{staticClass:"body-2"},[_c('span',[_vm._v(_vm._s(parseInt(_vm.totalBytes).toLocaleString('en-US'))+" bytes")])])],1)],1):_vm._e(),_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v("Active / total keys")]),_c(VListItemTitle,{staticClass:"body-2"},[_c('span',[_vm._v(_vm._s(_vm.bigmap.active_keys)+" / "+_vm._s(_vm.bigmap.total_keys))])])],1)],1),(_vm.bigmap.typedef)?_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v("Type")]),_c(VListItemTitle,[_c('TypeDef',{staticStyle:{"opacity":"1"},attrs:{"typedef":_vm.bigmap.typedef,"first":_vm.bigMapName}})],1)],1)],1):_vm._e()],1),(_vm.actions.length > 0)?_c(VList,{staticClass:"bigmap-sidebar-list mt-2"},_vm._l((_vm.actions),function(item,idx){return _c('div',{key:idx},[(idx > 0)?_c(VDivider):_vm._e(),_c('BigMapAction',{attrs:{"item":item}})],1)}),0):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }