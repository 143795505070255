import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{ref:"opgDialog",attrs:{"width":"1200","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{attrs:{"flat":"","outlined":""}},[_c(VCardTitle,{staticClass:"sidebar d-flex justify-center pa-4"},[_c('span',{staticClass:"body-1 font-weight-medium text-uppercase text--secondary"},[_vm._v("Simulation result")]),(_vm.simulatedOperation && _vm.settings.source)?_c('div',{staticClass:"d-flex flex-column align-center ml-10"},[_c('span',{staticClass:"overline"},[_vm._v("gas limit")]),_c('span',{staticClass:"hash",staticStyle:{"font-size":"14px","line-height":"14px"}},[_vm._v(_vm._s(_vm.gasLimit))])]):_vm._e(),(_vm.simulatedOperation && _vm.settings.source)?_c('div',{staticClass:"d-flex flex-column align-center ml-10"},[_c('span',{staticClass:"overline"},[_vm._v("storage limit")]),_c('span',{staticClass:"hash",staticStyle:{"font-size":"14px","line-height":"14px"}},[_vm._v(_vm._s(_vm.storageLimit))])]):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pa-0"},[_c('InternalOperation',{attrs:{"data":_vm.simulatedOperation,"noheader":""}}),_vm._l((_vm.simulatedOperation.internal_operations),function(intop,intid){return [_c(VDivider,{key:'divider' + intid}),_c('InternalOperation',{key:intid,attrs:{"data":intop,"mainOperation":_vm.simulatedOperation}})]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }