import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{ref:"confirmDialog",attrs:{"max-width":400,"persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.close.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"sidebar d-flex justify-center py-1 pa-2"},[_c(VIcon,{attrs:{"small":"","color":"orange"}},[_vm._v("mdi-alert")]),_c('span',{staticClass:"body-1 ml-2 font-weight-medium text-uppercase text--secondary"},[_vm._v("Warning")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pa-4 text--primary"},[_vm._v("The networks of the active wallet and the current contract do not match. ")]),_c(VCardActions,{staticClass:"pt-3 justify-space-between px-5"},[_c(VBtn,{attrs:{"color":"error","text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("Continue anyway ")]),_c(VBtn,{attrs:{"color":"primary","text":""},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v("Change wallet")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }