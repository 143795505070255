import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{staticClass:"ma-0",attrs:{"href":_vm.$sanitize(_vm.link),"target":"_blank","rel":"nofollow noopener","two-line":""}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.name))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.subtitle))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }