import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"px-8 pt-4"},[_c(VCol,{staticClass:"pl-4",attrs:{"cols":"3"}},[_c(VListItemGroup,{staticClass:"pt-0 pb-0 themed-border radius-1",attrs:{"mandatory":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.events),function(event,idx){return [_c(VListItem,{key:idx},[_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticClass:"accent--text"},[_vm._v(_vm._s(event.tag))]),_vm._v("  "),_c('span',{staticClass:"text--secondary"},[_vm._v("occured at level")]),_vm._v("  "),_c('span',{staticClass:"text--primary"},[_vm._v(_vm._s(event.level))])]),_c(VListItemSubtitle,{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm._f("fromNow")(event.timestamp)))])],1)],1),(idx !== _vm.events.length -1)?_c(VDivider,{key:'d' + idx}):_vm._e()]})],2)],1),_c(VCol,{staticClass:"pr-4",attrs:{"cols":"9"}},[(_vm.item)?_c(VCard,{staticClass:"sticky-card",attrs:{"flat":"","outlined":"","width":"95%"}},[_c(VCardText,{staticClass:"data pa-0 pb-4"},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline accent--text"},[_vm._v(_vm._s(_vm.item.tag))]),_c(VListItemSubtitle,[_c('router-link',{staticClass:"text--secondary caption",staticStyle:{"text-decoration":"none"},attrs:{"exact":"","to":`/${_vm.network}/opg/${_vm.item.hash}`}},[_vm._v(" "+_vm._s(_vm.item.hash)+" "),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)],1)],1),_c(VListItemAction,{staticClass:"justify-end"},[_c(VListItemActionText,{staticClass:"caption"},[_vm._v(_vm._s(_vm._f("formatShortTime")(_vm.item.timestamp)))]),_c(VListItemActionText,{staticClass:"caption"},[_vm._v("Block "+_vm._s(_vm.item.level))])],1)],1),_c(VDivider),_c('MiguelTreeView',{staticClass:"mt-6",attrs:{"miguel":_vm.item.payload,"network":_vm.network,"openAll":""}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }