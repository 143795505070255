export const SEARCH_TABS = {
  0: 'everywhere',
  1: 'contracts',
  2: 'operations',
  3: 'big_maps',
  4: 'tokens',
  5: 'metadata',
  6: 'suggests',
  7: 'contract',
  8: 'operation',
}