import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"px-8 py-4 canvas fill-canvas",attrs:{"fluid":""}},[_c(VSkeletonLoader,{attrs:{"loading":_vm.loading,"type":"card-heading, image"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[(_vm.storageVersions.length > 0)?_c(VSelect,{staticClass:"mb-1",staticStyle:{"max-width":"175px"},attrs:{"items":_vm.storageVersions,"item-text":"version","item-value":"level","rounded":"","dense":"","background-color":"data","hide-details":""},on:{"change":function($event){return _vm.getStorage(true)}},model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}}):_vm._e(),_c(VBreadcrumbs,{attrs:{"divider":"/","items":_vm.breadcrumbsItems}})],1),(_vm.isStorageParentPage)?_c('div',[_c(VBtn,{staticClass:"text--secondary",attrs:{"small":"","text":""},on:{"click":function($event){_vm.showRaw = true}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-code-json")]),_c('span',[_vm._v("Raw JSON")])],1),(!_vm.raw)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"ml-2 text--secondary",attrs:{"small":"","text":"","loading":_vm.downloading,"disabled":_vm.downloading},on:{"click":_vm.downloadFile}},on),[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-download-outline")]),_c('span',[_vm._v("Full dump")])],1)]}}],null,false,3257887049)},[_vm._v(" Raw snapshot with all big map data ")]):_vm._e(),(_vm.rawStorage && _vm.raw)?_c(VBtn,{staticClass:"ml-2",attrs:{"small":"","text":""},on:{"click":() => {
                _vm.copy(_vm.rawStorage)
              }}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-content-copy")]),_c('span',{staticClass:"text--secondary"},[_vm._v("Copy")])],1):_vm._e(),(_vm.raw)?_c(VBtn,{staticClass:"ml-2 text--secondary",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.getStorage()}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-file-tree")]),_c('span',[_vm._v("Switch to Tree View")])],1):_c(VBtn,{staticClass:"ml-2 text--secondary",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.getStorageRaw()}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-code-parentheses")]),_c('span',[_vm._v("Switch to Micheline")])],1)],1):(_vm.isKeyHashPage)?_c('portal-target',{attrs:{"name":"storage-actions"}}):_vm._e()],1),(_vm.storage || _vm.rawStorage)?_c(VCard,{staticClass:"pa-0 mt-2",attrs:{"tile":"","flat":"","outlined":_vm.isStorageParentPage}},[_c(VCardText,{staticClass:"pa-0 data"},[(_vm.isStorageParentPage)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"7"}},[(_vm.raw)?_c('Michelson',{attrs:{"code":_vm.rawStorage}}):_c('div',{staticClass:"py-4 data"},[_c('MiguelTreeView',{attrs:{"address":_vm.address,"miguel":_vm.storage,"network":_vm.network,"openAll":"","compact-pair":""}})],1)],1),_c(VDivider,{attrs:{"vertical":""}}),(_vm.schema)?_c(VCol,{staticClass:"pb-2",attrs:{"cols":"4"}},[_c('TypeDef',{staticClass:"pt-3 pb-1 px-6",staticStyle:{"opacity":"0.8"},attrs:{"typedef":_vm.schema.typedef,"first":"storage"}})],1):_vm._e()],1):_c('router-view',{attrs:{"network":_vm.network}})],1)],1):_c('ErrorState')],1)],1)],1),_c('RawJsonViewer',{attrs:{"type":"storage","network":_vm.network,"address":_vm.address,"level":_vm.level},model:{value:(_vm.showRaw),callback:function ($$v) {_vm.showRaw=$$v},expression:"showRaw"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }