import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanel,{staticClass:"bl-1 br-1 bt-1 bmd-panel",attrs:{"active-class":"bmd-active-panel"},on:{"change":_vm.onPanelStateChanged}},[(!_vm.single)?_c(VExpansionPanelHeader,{staticClass:"pa-0 pr-4",class:_vm.is_active ? 'item-header-applied' : 'item-header-failed'},[_c(VRow,{staticClass:"big-map-row",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"d-flex align-center justify-start text-truncate",attrs:{"cols":"10"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"hash"},[_c('span',[_vm._v(_vm._s(_vm.name || _vm.key_hash))])])],1)],1)],1),_c(VCol,{staticClass:"d-flex align-center text-truncate",attrs:{"cols":"2"}},[_c(VListItem,{staticClass:"pl-0"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"hash font-weight-light"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.timestamp)))]),_c(VListItemSubtitle,{staticClass:"hash font-weight-light"},[_vm._v(_vm._s(_vm.is_active ? 'active' : 'removed'))])],1)],1)],1)],1)],1):_vm._e(),_c(VExpansionPanelContent,[(_vm.loading)?_c(VSkeletonLoader,{attrs:{"loading":_vm.loading,"type":"article","transition":"fade-transition"}}):_c(VRow,{staticClass:"py-2 pr-7 pl-1",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"8"}},[_c(VListItem,{staticClass:"pl-1"},[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v("Key hash")]),_c(VListItemTitle,{staticClass:"d-flex align-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.key_hash)+" ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":() => {
                          _vm.$clipboard(_vm.key_hash);
                          _vm.showClipboardOK();
                        }}},on),[_c(VIcon,{staticClass:"text--secondary",attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}])},[_vm._v(" Copy key hash ")])],1)],1)],1)],1),_c(VCol,{staticClass:"d-flex align-center justify-end",attrs:{"cols":"4"}},[(!_vm.single)?_c(VBtn,{attrs:{"small":"","text":"","to":{
              name: 'big_map_history',
              params: {
                network: _vm.network,
                address: _vm.address,
                ptr: _vm.ptr,
                keyhash: _vm.key_hash
              }
            }}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-history")]),_c('span',[_vm._v("View history")])],1):_vm._e(),_c('portal',{attrs:{"to":"storage-actions"}},[(_vm.diff.value)?_c(VBtn,{staticClass:"mr-4 cursor-pointer",attrs:{"small":"","text":""},on:{"click":function($event){_vm.showRaw = true}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-code-braces")]),_c('span',[_vm._v("Raw JSON")])],1):_vm._e()],1)],1),_c(VCol,{staticClass:"px-2 py-4 my-2 ba-1",attrs:{"cols":"12"}},[_c('span',{staticClass:"overline ml-3"},[_vm._v("Key")]),_c('MiguelTreeView',{attrs:{"miguel":_vm.diff.key,"network":_vm.network,"openAll":""}})],1),(_vm.diff.value)?_c(VCol,{staticClass:"px-2 py-4 my-2 ba-1",attrs:{"cols":"12"}},[_c('span',{staticClass:"overline ml-3"},[_vm._v("Value")]),_c('MiguelTreeView',{attrs:{"miguel":_vm.diff.value,"network":_vm.network,"openAll":""}})],1):_vm._e()],1)],1),(_vm.diff)?_c('RawJsonViewer',{attrs:{"type":"big_map","network":_vm.network,"level":_vm.diff.last_update_level,"address":_vm.address,"ptr":_vm.ptr,"keyhash":_vm.key_hash},model:{value:(_vm.showRaw),callback:function ($$v) {_vm.showRaw=$$v},expression:"showRaw"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }