import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center",on:{"click":function($event){return _vm.$emit('executeAction')}}},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"px-6",attrs:{"outlined":"","loading":_vm.execution,"color":"accent"},on:{"click":function($event){return _vm.$emit('executeClick')}}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Execute")]),_c(VIcon,{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-creation")])],1)]}}])},[(_vm.executeActions)?_c(VList,[_vm._l((_vm.executeActions),function(item,index){return [(item.callback)?_c(VListItem,{key:index,on:{"click":function($event){return item.callback()}}},[_c(VListItemTitle,[_vm._v(_vm._s(item.text))]),_c(VListItemAvatar,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1)],1):_vm._e()]})],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }