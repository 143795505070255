import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.prim === 'lambda')?_c('Michelson',{attrs:{"code":_vm.value}}):(_vm.isPreFormatted)?_c('pre',[_vm._v(_vm._s(_vm.value))]):(_vm.prim === 'string' || _vm.prim === 'bytes')?_c(VTextarea,{attrs:{"auto-grow":"","rows":"1","filled":"","readonly":"","label":_vm.label,"value":_vm.value},on:{"click":() => {
      _vm.copy(_vm.value)
    }}}):_c(VTextField,{attrs:{"value":_vm.value,"suffix":_vm.suffix,"readonly":"","filled":"","label":_vm.label},on:{"click":() => {
      _vm.copy(_vm.value)
    }}}),_c(VBtn,{class:_vm.isPreFormatted ? 'pl-0 mt-4' : '',attrs:{"text":"","small":"","link":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (() => {
        _vm.copy(_vm.value)
      }).apply(null, arguments)}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-content-copy")]),_c('span',[_vm._v("Copy Value")])],1),(_vm.value !== _vm.$route.params.address && _vm.isAddress)?_c(VBtn,{attrs:{"text":"","small":"","link":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleAddress(_vm.value)}}},[(!_vm.sameTab)?_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]):_vm._e(),_c('span',[_vm._v("View")])],1):(_vm.isIpfsHash)?_c(VBtn,{attrs:{"text":"","small":"","link":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleIpfsHash(_vm.value)}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_c('span',[_vm._v("View IPFS")])],1):(_vm.isIpfsURI)?_c(VBtn,{attrs:{"text":"","small":"","link":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleIpfsURI(_vm.value)}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_c('span',[_vm._v("View IPFS")])],1):_vm._e(),(_vm.isTezosStorage)?_c(VBtn,{attrs:{"text":"","small":"","link":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleStorage(_vm.value)}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_c('span',[_vm._v("View Storage")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }