import { render, staticRenderFns } from "./ViewsTab.vue?vue&type=template&id=ad9411e8&scoped=true&"
import script from "./ViewsTab.vue?vue&type=script&lang=js&"
export * from "./ViewsTab.vue?vue&type=script&lang=js&"
import style0 from "./ViewsTab.vue?vue&type=style&index=0&id=ad9411e8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad9411e8",
  null
  
)

export default component.exports