import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto sticky-card",attrs:{"outlined":"","loading":_vm.loading}},[_c(VCardText,[_c(VList,[_c(VListItem,{staticClass:"px-3",attrs:{"two-line":""}},[_c(VListItemAvatar,{attrs:{"size":"50"}},[_c(VIcon,{attrs:{"size":"50"}},[_vm._v("mdi-database")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticClass:"text-h4 text--primary"},[_vm._v(_vm._s(_vm.helpers.shortcutOnly(_vm.item.body.KeyHash)))])]),_c(VListItemSubtitle,[_c('span',{staticClass:"secondary--text overline"},[_vm._v(_vm._s(_vm.item.body.Network))])])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"color":"primary","target":"_blank","to":_vm.link,"text":"","small":""}},[_vm._v("View")])],1)],1),_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticClass:"overline"},[_vm._v("Pointer")])]),_c(VListItemSubtitle,[_c('span',{staticClass:"hash"},[_vm._v(_vm._s(_vm.item.body.BigMapID))])])],1)],1),_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticClass:"overline"},[_vm._v("Key hash")])]),_c(VListItemSubtitle,[_c('span',{staticClass:"hash"},[_vm._v(_vm._s(_vm.item.body.KeyHash))])])],1)],1),_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticClass:"overline"},[_vm._v("Status")])]),_c(VListItemSubtitle,[_c('span',{staticClass:"hash"},[_vm._v(_vm._s(_vm.item.body.IsActive ? 'Active' : 'Removed'))])])],1)],1)],1)],1),_c(VCardActions,{staticClass:"pl-8 mb-4"},[_c(VBtn,{attrs:{"color":"primary","target":"_blank","outlined":"","small":"","to":_vm.link}},[_vm._v(" Key page ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }