import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto sticky-card",attrs:{"outlined":"","loading":_vm.loading}},[_c(VCardText,[_c(VList,[_c(VListItem,{staticClass:"px-3",attrs:{"two-line":""}},[_c(VListItemAvatar,{attrs:{"size":"50"}},[_c(VIcon,{attrs:{"size":"50"}},[_vm._v("mdi-swap-horizontal")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticClass:"text-h4 text--primary"},[_vm._v(_vm._s(_vm.helpers.shortcutOnly(_vm.item.body.Hash)))])]),_c(VListItemSubtitle,[_c('span',{staticClass:"secondary--text overline"},[_vm._v(_vm._s(_vm.item.body.Network))]),(_vm.info)?_c('span',{staticClass:"overline"},[_vm._v(" | "+_vm._s(_vm.status))]):_vm._e()])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"color":"primary","target":"_blank","to":_vm.link,"text":"","small":""}},[_vm._v("Operation group page")])],1)],1),(_vm.info)?_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticClass:"overline"},[_vm._v("Timestamp")])]),_c(VListItemSubtitle,[_c('span',{staticClass:"hash"},[_vm._v(_vm._s(_vm._f("formatTime")(_vm.info.timestamp)))])])],1)],1):_vm._e(),(_vm.info)?_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticClass:"overline"},[_vm._v("Level")])]),_c(VListItemSubtitle,[_c('span',{staticClass:"hash"},[_vm._v(_vm._s(_vm.info.level))])])],1)],1):_vm._e()],1)],1),(_vm.item.body.Hash)?_c(VCardActions,{staticClass:"pl-8 mb-4"},[_c(VBtn,{attrs:{"color":"primary","target":"_blank","outlined":"","small":"","to":_vm.link}},[_vm._v(" Operation group page ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }