import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pt-0",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"pt-0 pl-0",attrs:{"cols":"3"}},[_c('ReferenceContract',{attrs:{"network":_vm.network}})],1),_c(VCol,{staticClass:"pt-0 px-8",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex justify-end mt-2"},[_c(VSpacer),_c(VBtn,{staticClass:"mr-1 text--secondary",attrs:{"small":"","text":""},on:{"click":() => {
              _vm.copy(_vm.getValueToCopy())
            }}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-content-copy")]),_c('span',[_vm._v("Copy")])],1),_c(VBtn,{staticClass:"text--secondary",attrs:{"small":"","text":""},on:{"click":function($event){_vm.showRaw = true}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-code-json")]),_c('span',[_vm._v("Raw JSON")])],1),_c(VBtn,{staticClass:"text--secondary ml-2",attrs:{"small":"","text":""},on:{"click":_vm.downloadFile}},[_c(VIcon,{staticClass:"mr-1 text--secondary",attrs:{"small":""}},[_vm._v("mdi-download-outline")]),_c('span',[_vm._v("Download")])],1)],1),_c(VCard,{staticClass:"pa-0 mt-2",attrs:{"tile":"","flat":"","outlined":""}},[_c(VCardText,{staticClass:"pa-0"},[_c('Michelson',{attrs:{"code":_vm.constantInfo.code}})],1)],1)],1),_c(VCol,{staticClass:"pt-0 pl-0",attrs:{"cols":"3"}},[_c('ConstantInfo',{attrs:{"contract-info-items":_vm.constantInfoItems,"loading":_vm.loading}})],1)],1),_c('RawJsonViewer',{attrs:{"raw":_vm.constantInfo.value,"type":"constants","network":_vm.network,"address":_vm.address,"level":0},model:{value:(_vm.showRaw),callback:function ($$v) {_vm.showRaw=$$v},expression:"showRaw"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }