import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-8 canvas fill-canvas",attrs:{"fluid":""}},[(_vm.rawData)?_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{staticClass:"text--secondary ml-2",attrs:{"small":"","text":""},on:{"click":_vm.downloadFile}},[_c(VIcon,{staticClass:"mr-1 text--secondary",attrs:{"small":""}},[_vm._v("mdi-download-outline")]),_c('span',[_vm._v("Download")])],1)],1):_vm._e(),_c(VCard,{staticClass:"mt-2",attrs:{"tile":"","flat":"","outlined":""}},[_c(VCardTitle,[_vm._v("TZIP-16")]),_c(VCardText,{staticClass:"data pa-0"},[(_vm.error && _vm.metadata.metadata === null)?_c(VAlert,{staticClass:"ma-0 align-center",attrs:{"type":"error","prominent":"","text":""}},[_c('div',{staticClass:"overline"},[_vm._v("Resolving metadata error")]),_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.error))])]):_vm._e(),(_vm.rawData)?_c('vue-json-pretty',{attrs:{"data":_vm.rawData,"highlightMouseoverNode":true,"customValueFormatter":_vm.formatValue}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }