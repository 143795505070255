import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCombobox,{attrs:{"hide-details":"","hide-selected":"","items":_vm.items,"placeholder":_vm.placeholder,"multiple":"","outlined":"","clearable":"","dense":"","rounded":"","persistent-hint":"","small-chips":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to create a new one")])],1)],1)]},proxy:true},{key:"selection",fn:function({ attrs, item, selected }){return [_c(VChip,_vm._b({staticClass:"chip tag",attrs:{"input-value":selected,"close":"","close-icon":"mdi-close","small":"","active":""},on:{"click:close":function($event){return _vm.removeTag(item)}}},'v-chip',attrs,false),[_c('span',{staticClass:"caption text-truncate tag-text"},[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})
}
var staticRenderFns = []

export { render, staticRenderFns }