import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height bg-canvas-base"},[_c(VRow,{staticClass:"bg-canvas-base"},[_c(VCol,{staticClass:"pr-4 pb-4 d-flex align-center",staticStyle:{"padding-top":"10px"},attrs:{"cols":"9"}},[_c(VBreadcrumbs,{staticClass:"pl-0 pb-0 pt-0 ml-10",attrs:{"divider":"/","items":_vm.breadcrumbsItems}}),_c('Tags',{attrs:{"tags":_vm.contract.tags}})],1),_c(VCol,{staticClass:"d-flex justify-end pr-7",attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center justify-start pa-2 px-4"},[_c('BookmarkButton',{attrs:{"customClass":'mr-2 pl-2 pr-2 text--secondary',"isTextButton":true,"showEmpty":true,"network":_vm.network,"address":_vm.address,"alias":_vm.alias}}),_c(VBtn,{staticClass:"mr-2 pl-2 pr-2 text--secondary",attrs:{"outlined":"","small":""},on:{"click":() => {
              _vm.$clipboard(_vm.address);
              _vm.showClipboardOK();
            }}},[_c(VIcon,{staticClass:"text--secondary",attrs:{"small":""}},[_vm._v("mdi-content-copy")]),_c('span',{staticClass:"ml-1 text--secondary"},[_vm._v(" Copy address ")])],1),_c(VBtn,{staticClass:"mr-2 pl-2 pr-2 text--secondary",attrs:{"outlined":"","small":""},on:{"click":() => {
              _vm.openInTzkt();
            }}},[_c(VIcon,{staticClass:"text--secondary",attrs:{"small":""}},[_vm._v("mdi-logout-variant")]),_c('span',{staticClass:"ml-1 text--secondary"},[_vm._v(" Open in TZKT ")])],1)],1)])],1),_c('MenuToolbar',{staticClass:"pl-7",attrs:{"contract":_vm.contract,"network":_vm.network,"address":_vm.address,"tokensTotal":_vm.tokensTotal,"metadata":_vm.metadata,"is-anything-loading":_vm.isLoadingDataForTabs,"on-chain-views":_vm.onChainViews}}),_c(VContainer,{staticClass:"pt-0",attrs:{"fluid":""}},[_c('router-view',{attrs:{"accountType":_vm.accountType,"address":_vm.address,"network":_vm.network,"alias":_vm.alias,"contract":_vm.contract,"balance":_vm.balance,"tokensTotal":_vm.tokensTotal,"metadata":_vm.metadata,"same-count":_vm.contract ? _vm.contract.same_count: 0,"on-chain-views":_vm.onChainViews}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }