import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.displayedErrors),function(err,idx){return _c(VAlert,{key:idx,staticClass:"my-2",attrs:{"prominent":"","text":"","type":"error"}},[_c(VRow,{attrs:{"align":"center","no-gutters":""}},[_c(VCol,{staticClass:"d-flex flex-column px-4",attrs:{"cols":"10"}},[_c('span',{staticClass:"hash"},[_vm._v(_vm._s(err.title))]),_c('span',{staticClass:"text--secondary body-1"},[_c('span',[_vm._v(_vm._s(err.descr))]),(err.with)?_c('span',{staticStyle:{"word-break":"break-all"}},[_vm._v(" with "),_c('span',{staticClass:"ml-2 hash font-weight-medium"},[_vm._v(_vm._s(err.with))])]):(err.id.includes('contract.balance_too_low'))?_c('span',{staticStyle:{"word-break":"break-all"}},[_vm._v(": "+_vm._s(_vm._f("uxtz")(err.balance))+" < "+_vm._s(_vm._f("uxtz")(err.amount)))]):_vm._e()])]),_c(VSpacer),(err.id.includes('michelson_v1.script_rejected') && _vm.operationId)?_c(VCol,{staticClass:"d-flex justify-end align-center",attrs:{"cols":"2"}},[_c(VBtn,{staticClass:"text--secondary",attrs:{"small":"","text":"","loading":_vm.errorLocationLoading},on:{"click":_vm.getErrorLocation}},[_vm._v("Show failed code")])],1):_vm._e(),(err.id.includes('invalidSyntacticConstantError') && _vm.operationId)?_c(VCol,{staticClass:"d-flex justify-end align-center",attrs:{"cols":"2"}},[_c(VBtn,{staticClass:"text--secondary",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.showParameterError(err)}}},[_vm._v("Show mismatch")])],1):_vm._e()],1)],1)}),(_vm.errorLocation)?_c(VDialog,{attrs:{"width":"800"},model:{value:(_vm.showErrorLocation),callback:function ($$v) {_vm.showErrorLocation=$$v},expression:"showErrorLocation"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c('Michelson',{attrs:{"code":_vm.errorLocation.text,"firstLineNumber":_vm.errorLocation.first_row,"mark":{
            row: _vm.errorLocation.failed_row - _vm.errorLocation.first_row, 
            start: _vm.errorLocation.start_col, 
            end: _vm.errorLocation.end_col
          }}})],1)],1)],1):_vm._e(),_c('TreeNodeDetails',{attrs:{"data":_vm.treeNodeData,"network":_vm.network},model:{value:(_vm.showTreeNodeView),callback:function ($$v) {_vm.showTreeNodeView=$$v},expression:"showTreeNodeView"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }