import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VSkeletonLoader,{attrs:{"loading":_vm.loading,"type":"list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"}},[_c(VList,{staticClass:"stats-sidebar-list ba-1 bg-database"},[_c(VListItemGroup,{attrs:{"mandatory":""},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_vm._l((_vm.states),function(state,idx){return [(idx > 0)?_c(VDivider,{key:'divider' + idx}):_vm._e(),_c(VListItem,{key:idx,on:{"click":function($event){return _vm.navigate(state)}}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"sidebar-title text--primary"},[_vm._v(_vm._s(state.network))]),_c(VListItemSubtitle,{staticClass:"sidebar-subtitle"},[_vm._v(_vm._s(state.protocol.slice(0, 8)))])],1),_c(VListItemAction,[_c(VListItemActionText,{staticClass:"overline text--primary"},[_vm._v(_vm._s(_vm.helpers.formatDatetime(state.timestamp)))])],1)],1)]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }