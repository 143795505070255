import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{ref:"michelsonDialog",attrs:{"width":"800","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{attrs:{"flat":"","outlined":""}},[_c(VCardTitle,{staticClass:"sidebar d-flex justify-center py-2"},[_c('span',{staticClass:"body-1 font-weight-medium text-uppercase text--secondary"},[_vm._v("Michelson")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pa-0"},[_c('Michelson',{attrs:{"code":_vm.code}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }