import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"550","persistent":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.show = false},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.saveOnEnter.apply(null, arguments)}]},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{attrs:{"flat":"","outlined":""}},[_c(VCardTitle,{staticClass:"sidebar d-flex justify-center py-2"},[_c('span',{staticClass:"body-1 font-weight-medium text-uppercase text--secondary"},[_vm._v("Your bookmark name")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.show = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pt-3 pb-0"},[_c(VTextField,{attrs:{"hide-details":"","label":"Alias","autofocus":"","placeholder":"Type here custom contract name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c(VCardActions,{staticClass:"pb-3 px-6 d-flex justify-space-between justify-end align-end"},[_c('div',[_c('span',{staticClass:"text--disabled"},[_vm._v("In bookmarks, will look like:")]),(_vm.name)?_c(VListItem,{staticClass:"item py-0 mt-1 px-3"},[_c(VListItemContent,{staticClass:"pa-0"},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.name))]),_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v(_vm._s(_vm.network))])],1),_c(VListItemAction,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-star")])],1)],1):_vm._e()],1),_c(VBtn,{staticClass:"d-flex justify-center",attrs:{"color":"primary","text":""},on:{"click":_vm.add}},[_vm._v("Add")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }