import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{ref:"accountBox",attrs:{"width":"800"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.show = false}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VListItem,_vm._g({staticClass:"link",class:_vm.gutters ? '' : 'pa-0 ma-0 mr-3',attrs:{"selectable":""}},on),[_c(VListItemContent,[(_vm.title)?_c(VListItemSubtitle,{class:_vm.lowerTitle ? 'lower-overline' : 'overline'},[_c('span',[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_c(VListItemTitle,[_c('span',{staticClass:"body-1",class:_vm.highlighted ? 'text--secondary' : ''},[(_vm.alias)?_c('span',[_vm._v(_vm._s(_vm.alias))]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.helpers.shortcut(_vm.address))}})]),(_vm.highlighted)?_c(VIcon,{staticClass:"ml-1 mb-1 text--disabled",attrs:{"small":""}},[_vm._v("mdi-radiobox-marked")]):_vm._e()],1)],1)],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{staticClass:"data"},[_c(VCardTitle,{staticClass:"py-3 px-6 align-center sidebar"},[_c('span',{staticClass:"body-1 font-weight-medium text-uppercase text--secondary mr-2"},[_vm._v(" "+_vm._s(_vm.title)+": ")]),(_vm.alias)?_c('span',{staticClass:"ml-2 body-1"},[_vm._v(_vm._s(_vm.alias))]):_c('span',{staticClass:"ml-2 body-1",domProps:{"innerHTML":_vm._s(_vm.helpers.shortcut(_vm.address))}}),_c(VSpacer),_c('span',{staticClass:"caption text-uppercase font-weight-medium",class:_vm.network === 'mainnet' ? 'primary--text' : 'text--secondary'},[_vm._v(_vm._s(_vm.network))])],1),_c(VCardText,{staticClass:"pt-7"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('ValueInspector',{attrs:{"prim":"address","value":_vm.address,"network":_vm.network,"label":"Address"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }