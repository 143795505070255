import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VList } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanels,{attrs:{"flat":"","hover":"","tile":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"canvas caption font-weight-medium text-uppercase text--disabled"},[_vm._v(_vm._s(_vm.helpers.plural(_vm.files.length, 'file')))]),_c(VExpansionPanelContent,{staticClass:"pa-0 ma-0"},[_c(VList,{staticClass:"pa-0 ma-0"},_vm._l((_vm.items),function(file,idx){return _c('FileTreeItem',{key:idx,attrs:{"file":file}})}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }