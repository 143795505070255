import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.account)?_c(VMenu,{attrs:{"offset-y":"","tile":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"text--secondary",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"26"}},[_vm._v("mdi-wallet")])],1)]}}],null,false,3640290248)},[_c(VList,{staticClass:"py-0"},[_c(VSubheader,{staticClass:"overline"},[_vm._v("Select network")]),_c(VDivider),_vm._l((_vm.config.networks),function(network){return _c(VListItem,{key:network,staticClass:"pr-8 pl-4 cursor-pointer",on:{"click":function($event){return _vm.auth(network)}}},[_c(VListItemTitle,{staticClass:"text-capitalize"},[_vm._v(_vm._s(network))])],1)})],2)],1):_c('div',[_c(VMenu,{attrs:{"offset-y":"","tile":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"padding-top":"4px"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('img',{staticClass:"avatar",attrs:{"alt":"avatar","src":`https://catava.dipdup.net/${_vm.account.address}`}})])]}}]),model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c(VList,{staticClass:"py-0",attrs:{"max-width":"250"}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.account.walletName))]),_c(VListItemSubtitle,{directives:[{name:"past-html",rawName:"v-past-html",value:(_vm.helpers.shortcut(_vm.account.address, 6)),expression:"helpers.shortcut(account.address, 6)"}],staticClass:"hash"}),_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v(_vm._s(_vm.account.network.type))])],1),_c(VListItemAction,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mt-2",attrs:{"icon":""},on:{"click":function($event){{
                    _vm.$clipboard(_vm.account.address);
                    _vm.showClipboardOK();
                  }}}},on),[_c(VIcon,{staticClass:"text--secondary"},[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v("Copy wallet address")])])],1)],1),_c(VDivider),(!_vm.isChangeWallet)?[_c(VListItem,{staticClass:"px-4 cursor-pointer",on:{"click":function($event){_vm.isChangeWallet = true}}},[_c(VListItemTitle,[_vm._v("Change wallet")])],1),_c(VListItem,{staticClass:"px-4 cursor-pointer",on:{"click":_vm.logOut}},[_c(VListItemTitle,[_vm._v("Log out")])],1)]:_vm._l((_vm.config.networks),function(network){return _c(VListItem,{key:network,staticClass:"pr-8 pl-4 cursor-pointer",on:{"click":function($event){return _vm.auth(network, false)}}},[_c(VListItemTitle,{staticClass:"text-capitalize"},[_vm._v(_vm._s(network))])],1)})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }