import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"offset-y":"","max-height":"500","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"btn-class text--secondary",attrs:{"icon":""},on:{"click":_vm.updateBookmarks}},'v-btn',attrs,false),on),[(_vm.keysCount > 0)?_c(VIcon,[_vm._v("mdi-star")]):_c(VIcon,[_vm._v("mdi-star-outline")])],1)]}}])},[(_vm.keysCount > 0)?_c(VList,{staticClass:"pa-0"},_vm._l((_vm.items),function(item,index){return _c(VListItem,{key:index,attrs:{"to":_vm.getItemLink(item)}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.alias || item.address))]),_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v(_vm._s(item.network))])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.removeBookmark(index)}}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-star")])],1)],1)],1)}),1):_c(VList,[_c(VListItem,[_c('span',{staticClass:"text--secondary"},[_vm._v("You don't have favorite accounts yet. Add them by clicking on ⭐️ on the account page or on the Interact tab.")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }