import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCombobox,{attrs:{"search-input":_vm.searchText,"items":_vm.suggests,"loading":_vm.isSuggestionsLoading,"placeholder":"Search tokens by name","hint":`Tokens will be searched in current contract network: ${_vm.network}`,"autocomplete":"off","prepend-inner-icon":"mdi-magnify","background-color":"data","item-text":item => _vm.getItemText(item),"outlined":"","dense":"","no-filter":"","append-icon":"","hide-no-data":"","clearable":"","hide-selected":"","persistent-hint":""},on:{"update:searchInput":function($event){_vm.searchText=$event},"update:search-input":function($event){_vm.searchText=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressCircular,{staticClass:"searchbox-custom-progress-circular",attrs:{"indeterminate":"","color":"var(--v-primary-base)","size":"24","width":"2"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c(VListItemAvatar,{attrs:{"width":"75"}},[_c(VChip,{staticClass:"caption chip hash",attrs:{"active-class":"primary--text","disabled":"","active":"","small":""}},[_vm._v(_vm._s(item.type == 1 ? 'FA1.2' : 'FA2'))])],1),(item.body)?_c(VListItemContent,[_c(VListItemTitle,[(item.body.Name)?_c('span',[_vm._v(_vm._s(item.body.Name))]):_c('Shortcut',{attrs:{"str":item.body.Address}})],1),_c(VListItemSubtitle,{staticClass:"hash"},[_c('span',[_vm._v(_vm._s(item.body.Address))]),_c('span',[_vm._v(" | ID "+_vm._s(item.body.TokenID))])])],1):_vm._e()]}}]),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}})
}
var staticRenderFns = []

export { render, staticRenderFns }