import { render, staticRenderFns } from "./TokensTab.vue?vue&type=template&id=4d7fdcce&scoped=true&"
import script from "./TokensTab.vue?vue&type=script&lang=js&"
export * from "./TokensTab.vue?vue&type=script&lang=js&"
import style0 from "./TokensTab.vue?vue&type=style&index=0&id=4d7fdcce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d7fdcce",
  null
  
)

export default component.exports