import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"canvas fill-canvas",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"pl-0",attrs:{"cols":"9"}},[_c(VBreadcrumbs,{staticClass:"pl-0 pb-0 pt-0 ml-10",attrs:{"divider":"/","items":_vm.breadcrumbsItems}})],1),_c(VCol,{staticClass:"d-flex flex-row-reverse pr-8",attrs:{"cols":"3"}},[_c('div',[_c(VBtn,{staticClass:"mr-2 pl-2 pr-2 text--secondary",attrs:{"text":"","small":""},on:{"click":() => {
                _vm.$clipboard(_vm.hash);
                _vm.showClipboardOK();
              }}},[_c(VIcon,{staticClass:"text--secondary",attrs:{"small":""}},[_vm._v("mdi-content-copy")]),_c('span',{staticClass:"ml-1 text--secondary"},[_vm._v(" Copy hash ")])],1),_c(VBtn,{staticClass:"mr-2 pl-2 pr-2 text--secondary",attrs:{"text":"","small":""},on:{"click":function($event){_vm.showRaw = true}}},[_c(VIcon,{staticClass:"text--secondary"},[_vm._v("mdi-code-json")]),_c('span',{staticClass:"ml-1 text--secondary"},[_vm._v(" Raw JSON ")])],1),_c(VBtn,{staticClass:"pl-2 text--secondary",attrs:{"text":"","small":""},on:{"click":() => {
                _vm.openInTzkt();
              }}},[_c(VIcon,{staticClass:"text--secondary",attrs:{"small":""}},[_vm._v("mdi-logout-variant")]),_c('span',{staticClass:"ml-1 text--secondary"},[_vm._v(" Open in TZKT ")])],1)],1)])],1),_c(VRow,{staticClass:"px-7"},[_c(VCol,{staticClass:"pr-7",attrs:{"cols":"9"}},[_c('OpgContents',{attrs:{"loading":_vm.loading,"operations":_vm.contents}})],1),_c(VCol,{staticClass:"pl-0",attrs:{"cols":"3"}},[(_vm.content)?_c(VList,[_c(VListItem,{staticStyle:{"height":"74px"}},[_c(VListItemContent,{attrs:{"two-line":""}},[_c(VListItemTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.content.hash)+" ")]),_c(VListItemSubtitle,[_c('span',{staticClass:"overline",class:_vm.network === 'mainnet' ? 'secondary--text' : ''},[_vm._v(" "+_vm._s(_vm.network))])])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v("Date")]),_c(VListItemTitle,{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.helpers.formatDatetime(_vm.content.timestamp))+" ")])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v("Level")]),_c(VListItemTitle,{staticClass:"body-2"},[_c('span',[_vm._v(_vm._s(_vm.content.level))])])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v("Total cost")]),_c(VListItemTitle,{staticClass:"body-2"},[_c('span',[_vm._v(_vm._s(_vm.totalCost))])])],1)],1)],1):_vm._e(),(_vm.content)?_c('CallStack',{attrs:{"network":_vm.network,"operations":_vm.contents}}):_vm._e()],1)],1),(_vm.content)?_c('RawJsonViewer',{attrs:{"type":"operation","network":_vm.network,"level":_vm.content.level,"hash":_vm.hash,"raw":_vm.content.rawMempool},model:{value:(_vm.showRaw),callback:function ($$v) {_vm.showRaw=$$v},expression:"showRaw"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }