import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-8 canvas fill-canvas",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-4",attrs:{"cols":"8"}},[_c(VSkeletonLoader,{attrs:{"loading":_vm.loading,"type":"card-heading, image"}},[(_vm.storage)?_c('Schema',{attrs:{"name":_vm.$route.params.address,"title":"Fork","type":"storage","header":"Storage","schema":_vm.storage.schema,"network":_vm.$route.params.network,"address":_vm.$route.params.address,"alias":_vm.alias},model:{value:(_vm.storage.default_model),callback:function ($$v) {_vm.$set(_vm.storage, "default_model", $$v)},expression:"storage.default_model"}}):_c('div')],1)],1),_c(VCol,{staticClass:"pl-4",attrs:{"cols":"4"}},[_c(VSkeletonLoader,{attrs:{"loading":_vm.loading,"type":"card-heading, image"}},[(_vm.storage)?_c(VCard,{staticClass:"pa-0",attrs:{"tile":"","flat":"","outlined":""}},[_c(VCardText,{staticClass:"data"},[_c('span',{staticClass:"hash ml-2 text--primary",staticStyle:{"font-size":"15px","font-weight":"400"}},[_vm._v("Storage type")]),_c('TypeDef',{staticClass:"pt-4 px-2",attrs:{"typedef":_vm.storage.typedef,"first":"storage"}})],1)],1):_c('div')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }