import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"ma-0 px-10 py-0 canvas",attrs:{"elevation":"0"}},[_c(VCardText,{staticClass:"px-10"},[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c('TagsCombobox',{attrs:{"label":"Tags","placeholder":"Add some tags"},model:{value:(_vm.filters.tags),callback:function ($$v) {_vm.$set(_vm.filters, "tags", $$v)},expression:"filters.tags"}}),_c('TagsCombobox',{staticClass:"mt-6",attrs:{"label":"Minters","placeholder":"Add minters"},model:{value:(_vm.filters.minters),callback:function ($$v) {_vm.$set(_vm.filters, "minters", $$v)},expression:"filters.minters"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c('TagsCombobox',{attrs:{"label":"Mime type","placeholder":"Add mime type","items":_vm.mimeTypes},model:{value:(_vm.filters.mime_types),callback:function ($$v) {_vm.$set(_vm.filters, "mime_types", $$v)},expression:"filters.mime_types"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c('TagsCombobox',{attrs:{"label":"Creators","placeholder":"Add creators"},model:{value:(_vm.filters.creators),callback:function ($$v) {_vm.$set(_vm.filters, "creators", $$v)},expression:"filters.creators"}}),_c('div',{staticClass:"d-flex justify-end mt-6"},[_c(VBtn,{staticClass:"primary--text",attrs:{"text":"","disabled":_vm.isBtnDisabled},on:{"click":_vm.applyFilters}},[_vm._v(" Apply filters ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }