import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{class:`hoverable-card mt-3 ${_vm.active ? 'active-search-result' : ''}`,attrs:{"flat":"","tile":"","ripple":false},on:{"click":_vm.onClick}},[_c(VCardText,{staticClass:"pa-0 pt-1"},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticClass:"hash"},[_vm._v("Token")]),_c('span',{staticClass:"text--secondary",staticStyle:{"font-size":"20px"}},[_vm._v(" → ")]),_c('router-link',{staticClass:"serp-link",attrs:{"target":"_blank","to":`/${_vm.item.body.Network}/${_vm.item.body.Address}/tokens?search=${_vm.item.body.TokenID}`}},[(_vm.item.body.Name)?_c('span',{staticClass:"alias"},[_vm._v(_vm._s(_vm.sanitize(_vm.item.body.Name)))]):_vm._e()])],1),_c(VListItemSubtitle,[_c('span',{staticClass:"hash",class:_vm.item.body.Network === 'mainnet' ? 'secondary--text' : ''},[_vm._v(_vm._s(_vm.item.body.Network))]),_c('span',{staticClass:"hash"},[_vm._v(" | "+_vm._s(_vm.item.body.Address))]),_c('span',{staticClass:"hash"},[_vm._v(" | ID "+_vm._s(_vm.item.body.TokenID))])])],1)],1),_c('Highlight',{staticClass:"mt-1",attrs:{"highlights":_vm.item.highlights}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }