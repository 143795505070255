import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,{staticClass:"v-content-class"},[_c('MainHeaderDescriptive',{attrs:{"no-search":_vm.isNoSearchInHeader}}),(!!_vm.$store.state.error)?_c('ErrorSnackbar'):_vm._e(),(!!_vm.$store.state.success)?_c('SuccessSnackbar'):_vm._e(),(!!_vm.$store.state.warning)?_c('WarningSnackbar'):_vm._e(),_c('router-view'),(_vm.$vuetify.breakpoint.smAndUp)?_c(VFabTransition,[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.showToTop),expression:"showToTop"}],attrs:{"color":"primary","fab":"","fixed":"","bottom":"","right":""},on:{"click":_vm.scrollToTop}},[_c(VIcon,[_vm._v("mdi-chevron-up")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }