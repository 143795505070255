import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"mx-5"},[_c(VCol,{staticClass:"d-flex justify-end align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"overline mr-3"},[_vm._v("points per")]),_c(VBtnToggle,{attrs:{"dense":"","mandatory":""},model:{value:(_vm.timeframe),callback:function ($$v) {_vm.timeframe=$$v},expression:"timeframe"}},[_c(VBtn,{attrs:{"small":"","value":"day"}},[_vm._v("Day")]),_c(VBtn,{attrs:{"small":"","value":"week"}},[_vm._v("Week")]),_c(VBtn,{attrs:{"small":"","value":"month"}},[_vm._v("Month")]),_c(VBtn,{attrs:{"small":"","value":"year"}},[_vm._v("Year")])],1)],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VListItemGroup,{staticClass:"themed-border radius-1",attrs:{"active-class":"token-card-selected","value":_vm.selected,"mandatory":""}},_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:'stats-' + i,staticClass:"token-card",on:{"click":function($event){_vm.selected = i}}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"overline"},[_c('span',[_vm._v(_vm._s(item.name))])]),_c(VListItemSubtitle,{staticClass:"caption"},[_c('span',[_vm._v(_vm._s(item.description))])])],1),_c(VListItemAction,[_c(VListItemActionText,{staticClass:"body-2 secondary--text"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("numberToCompactSIFormat")(item.value)))])]}}],null,true)},[_c('span',[_vm._v("Total "+_vm._s(item.name.toLowerCase()))])])],1)],1)],1)}),1)],1),_c(VCol,{attrs:{"cols":"8"}},[_c(VCard,{staticStyle:{"background-color":"transparent"},attrs:{"outlined":"","min-height":"400"}},[_c(VCardText,{staticClass:"ma-0 pa-0"},[_c(VSkeletonLoader,{attrs:{"loading":!_vm.selectedItem.series,"type":"image@2","tile":"","min-height":"400"}},[_c('LineChart',{staticClass:"ma-4",attrs:{"data":_vm.selectedItem.series,"name":_vm.selectedItem.name,"formatType":_vm.selectedItem.formatter,"exporting":""}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }