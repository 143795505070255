import { render, staticRenderFns } from "./TagsCombobox.vue?vue&type=template&id=666abd02&scoped=true&"
import script from "./TagsCombobox.vue?vue&type=script&lang=js&"
export * from "./TagsCombobox.vue?vue&type=script&lang=js&"
import style0 from "./TagsCombobox.vue?vue&type=style&index=0&id=666abd02&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "666abd02",
  null
  
)

export default component.exports