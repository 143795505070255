import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"hash"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.item.action)+" at "+_vm._s(_vm.item.level)+" level")]),(_vm.item.action == 'copy')?[(_vm.item.destination_ptr)?_c('span',{staticClass:"text--secondary font-weight-light"},[_vm._v("to")]):(_vm.item.source_ptr)?_c('span',{staticClass:"text--secondary font-weight-light"},[_vm._v("from")]):_vm._e()]:_vm._e()],2),_c(VListItemSubtitle,[_c('span',{staticClass:"overline"},[_vm._v(_vm._s(_vm.helpers.formatDatetime(_vm.item.timestamp)))])])],1),_c(VListItemAction,[(_vm.item.action == 'copy')?_c(VListItemActionText,[(_vm.item.destination_ptr)?_c(VBtn,{staticClass:"text--primary",attrs:{"to":`/${_vm.network}/big_map/${_vm.item.destination_ptr}`,"text":"","small":""}},[_c(VIcon,{staticClass:"mr-1 text--secondary",attrs:{"small":""}},[_vm._v("mdi-link-variant")]),_vm._v(" Big Map "+_vm._s(_vm.item.destination_ptr)+" ")],1):(_vm.item.source_ptr)?_c(VBtn,{staticClass:"text--primary",attrs:{"to":`/${_vm.network}/big_map/${_vm.item.source_ptr}`,"text":"","small":""}},[_c(VIcon,{staticClass:"mr-1 text--secondary",attrs:{"small":""}},[_vm._v("mdi-link-variant")]),_vm._v(" Big Map "+_vm._s(_vm.item.source_ptr)+" ")],1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }