import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{class:`hoverable-card mt-3 ${_vm.active ? 'active-search-result' : ''}`,attrs:{"flat":"","tile":"","ripple":false},on:{"click":_vm.onClick}},[_c(VCardText,{staticClass:"pa-0 pt-1"},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_c('router-link',{staticClass:"serp-link",attrs:{"target":"_blank","to":`/${_vm.item.body.Network}/big_map/${_vm.item.body.BigMapID}`}},[_c('span',{staticClass:"hash text--primary"},[_vm._v("Big_map "+_vm._s(_vm.item.body.BigMapID))])]),_c('span',{staticClass:"text--secondary",staticStyle:{"font-size":"20px"}},[_vm._v(" → ")]),_c('router-link',{staticClass:"serp-link",attrs:{"target":"_blank","to":`/${_vm.item.body.Network}/big_map/${_vm.item.body.BigMapID}/${_vm.item.body.KeyHash}`}},[_c('span',{staticClass:"hash"},[_vm._v(_vm._s(_vm.item.body.KeyHash))])])],1),_c(VListItemSubtitle,[_c('span',{staticClass:"hash",class:_vm.item.body.Network === 'mainnet' ? 'secondary--text' : ''},[_vm._v(_vm._s(_vm.item.body.Network))]),_c('span',{staticClass:"hash",class:_vm.item.body.IsActive ? 'secondary--text' : 'red'},[_vm._v(" | "+_vm._s(_vm.item.body.IsActive ? 'Active' : 'Removed'))])])],1)],1),_c('Highlight',{staticClass:"mt-1",attrs:{"highlights":_vm.item.highlights}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }