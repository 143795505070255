import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VFadeTransition,[_c(VDataTable,{class:['ba-1 mt-4 recently-called', {'hide-pagination-count' : _vm.hidePaginationCount}],attrs:{"items":_vm.recentlyCalledContracts,"headers":_vm.recentlyCalledTableHeaders,"hide-default-footer":"","page":_vm.page,"options":{itemsPerPage: _vm.itemsPerPage},"loading":_vm.loading,"loading-text":"Loading recently called contracts... Please wait","no-data-text":"No called contracts found","no-results-text":"No called contracts found","server-items-length":_vm.contractsNumber,"footer-props":{
          itemsPerPageOptions: []
      }},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function({item}){return [_c('tr',{key:item.address,staticClass:"table-row"},[_c('td',[_c(VBtn,{staticClass:"text--secondary hash",staticStyle:{"text-transform":"none"},attrs:{"to":`/${_vm.network}/${item.address}/`,"text":""}},[(item.alias)?_c('span',[_vm._v(" "+_vm._s(item.alias.length > _vm.aliasMaxLength ? item.alias.slice(0, _vm.aliasMaxLength).trim() : item.alias)+" "),(item.alias.length > _vm.aliasMaxLength)?_c('em',{staticClass:"v-icon notranslate mdi mdi-dots-horizontal",staticStyle:{"font-size":"16px"}}):_vm._e()]):_c('Shortcut',{attrs:{"str":item.address}})],1)],1),_c('td',[_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(item.operations_count))])]),_c('td',[_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.helpers.formatDatetime(item.last_action)))])])])]}},(_vm.pageable)?{key:"footer",fn:function(){return [_c('div',{staticClass:"footer-pagination"},[_c('span',{staticClass:"caption grey--text mr-2"},[_vm._v(_vm._s((_vm.page - 1) * _vm.itemsPerPage + 1)+" - "+_vm._s(_vm.nextPageCount)+" of "+_vm._s(_vm.contractsNumber))]),_c(VBtn,{attrs:{"icon":"","disabled":_vm.page === 1},on:{"click":function($event){return _vm.changePage(_vm.page - 1)}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VBtn,{attrs:{"icon":"","disabled":_vm.contractsNumber <= _vm.itemsPerPage * _vm.page},on:{"click":function($event){return _vm.changePage(_vm.page + 1)}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }