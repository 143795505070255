import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"canvas fill-canvas pa-8 ma-0",attrs:{"fluid":""}},[_c(VRow,[(_vm.hasTokens)?_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"color":"primary","placeholder":"Start typing token name","clearable":"","outlined":"","background-color":"data","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12"}},[(_vm.tokens.length === 0 && !_vm.loading)?_c('EmptyState',{attrs:{"icon":"mdi-code-brackets","title":"Nothing found"}}):_vm._e(),_c(VExpansionPanels,{directives:[{name:"show",rawName:"v-show",value:(_vm.tokens.length > 0),expression:"tokens.length > 0"}],staticClass:"bb-1",attrs:{"multiple":"","hover":"","flat":""}},_vm._l((_vm.tokens),function(token){return _c(VExpansionPanel,{key:token.token_id,staticClass:"bl-1 br-1 bt-1 token-panel",attrs:{"active-class":"token-active-panel"}},[_c(VExpansionPanelHeader,{staticClass:"py-0 px-4",class:_vm.getHeaderClass(token),attrs:{"ripple":"","title":_vm.title(token)}},[[_c(VListItem,{staticClass:"fill-height pa-0"},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.title(token)))]),_c(VListItemSubtitle,{staticClass:"font-weight-light hash text--secondary"},[_vm._v(" Token ID: "+_vm._s(token.token_id)+" ")])],1)],1)]],2),_c(VExpansionPanelContent,{staticClass:"token-content py-4"},[(token.link)?_c(VListItem,{staticClass:"pl-0"},[_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"overline"},[_vm._v("Token metadata link")]),_c(VListItemTitle,{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(token.link))]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":() => {
                            _vm.$clipboard(token.link);
                            _vm.showClipboardOK();
                          }}},on),[_c(VIcon,{staticClass:"text--secondary",attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_vm._v(" Copy token link ")])],1)],1)],1):_vm._e(),(token.status === 2 && token.metadata === null)?_c(VAlert,{staticClass:"ma-0 align-center",attrs:{"type":"error","prominent":"","text":""}},[_c('div',{staticClass:"overline"},[_vm._v("Resolving token metadata error "+_vm._s(token.retry_count ? `(after ${token.retry_count} attempts)`: ''))]),_c('div',{staticClass:"text--primary"},[_vm._v(_vm._s(token.error))])]):_vm._e(),(token.status === 1 && token.metadata === null)?_c(VAlert,{staticClass:"ma-0 align-center",attrs:{"icon":"mdi-progress-download","color":"grey","prominent":"","text":""}},[_c('span',{staticClass:"overline"},[_vm._v("We're trying to get your file. Please, wait...")])]):_vm._e(),(token.metadata)?_c(VRow,[_c(VCol,{attrs:{"cols":token.metadata.thumbnailUri ? 10 : 12}},[_c('vue-json-pretty',{staticClass:"py-3",attrs:{"data":token.metadata,"highlightMouseoverNode":true,"customValueFormatter":_vm.formatValue}})],1),(token.metadata.thumbnailUri)?_c(VCol,{staticClass:"d-flex flex-column align-center justify-start",attrs:{"cols":"2"}},[_c('div',{staticClass:"d-flex flex-column align-center justify-start"},[(token.metadata.thumbnailUri)?_c('TokenImage',{attrs:{"uri":token.metadata.thumbnailUri,"size":200}}):_vm._e()],1)]):_vm._e()],1):_vm._e()],1)],1)}),1),_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":""}})],1)],1),(!_vm.loading && !_vm.downloaded)?_c('span',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onDownloadPage),expression:"onDownloadPage"}]}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }