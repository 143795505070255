import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VFadeTransition,[_c(VSkeletonLoader,{staticClass:"global-constant-loader",attrs:{"loading":_vm.isGlobalConstantsLoading,"type":"article","transition":"fade-transition"}},[_c(VDataTable,{class:['ba-1 mt-4 bcd-table', {'hide-pagination-count' : _vm.hidePaginationCount}],attrs:{"items":_vm.globalConstantsItems,"headers":_vm.globalConstantsTableHeaders,"hide-default-footer":"","page":_vm.page,"options":{itemsPerPage: _vm.itemsPerPage},"no-data-text":"No global constants found","no-results-text":"No global constants found","server-items-length":_vm.constantsNumber,"footer-props":{
          itemsPerPageOptions: []
        }},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function({item}){return [_c('tr',{key:item.address,staticClass:"table-row"},[_c('td',[_c(VBtn,{staticClass:"text--secondary hash",staticStyle:{"text-transform":"none"},attrs:{"to":`/constant/${_vm.network}/${item.address}/`,"text":""}},[_c('span',{directives:[{name:"past-html",rawName:"v-past-html",value:(_vm.helpers.shortcut(item.address, 8)),expression:"helpers.shortcut(item.address, 8)"}]})])],1),_c('td',[_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.helpers.formatDatetime(item.timestamp)))])]),_c('td',[_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(item.level))])]),_c('td',[_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(item.links_count))])])])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer-pagination"},[_c('span',{staticClass:"caption grey--text mr-2"},[_vm._v(_vm._s((_vm.page - 1) * _vm.itemsPerPage + 1)+" - "+_vm._s(_vm.nextPageCount)+" of "+_vm._s(_vm.constantsNumber))]),_c(VBtn,{attrs:{"icon":"","disabled":_vm.page === 1},on:{"click":function($event){return _vm.changePage(_vm.page - 1)}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VBtn,{attrs:{"icon":"","disabled":_vm.constantsNumber <= _vm.itemsPerPage * _vm.page},on:{"click":function($event){return _vm.changePage(_vm.page + 1)}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }