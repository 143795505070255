import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c(VFadeTransition,[_c(VSkeletonLoader,{attrs:{"loading":_vm.loading,"type":"article","transition":"fade-transition"}},[(!_vm.isEmpty)?_c(VDataTable,{class:['ba-1 mt-4 referenced-contracts', 'hide-pagination-count'],attrs:{"items":_vm.contracts,"hide-default-header":"","hide-default-footer":"","page":_vm.page,"options":{itemsPerPage: _vm.itemsPerPage},"footer-props":{
          itemsPerPageOptions: []
      }},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function({item}){return [_c('tr',{staticClass:"table-row"},[_c('td',[_c(VBtn,{staticClass:"text--secondary hash",staticStyle:{"text-transform":"none"},attrs:{"to":`/${item.network}/${item.address}/`,"text":""}},[(item.alias)?_c('span',[_vm._v(_vm._s(item.alias.length > _vm.aliasMaxLength ? item.alias.slice(0, _vm.aliasMaxLength).trim() : item.alias)+" "),(item.alias.length > _vm.aliasMaxLength)?_c('em',{staticClass:"v-icon notranslate mdi mdi-dots-horizontal",staticStyle:{"font-size":"16px"}}):_vm._e()]):_c('span',{directives:[{name:"past-html",rawName:"v-past-html",value:(_vm.helpers.shortcut(item.address, 12)),expression:"helpers.shortcut(item.address, 12)"}]})])],1)])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("No references to contracts")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer-pagination"},[_c(VBtn,{attrs:{"icon":"","disabled":_vm.page === 0},on:{"click":function($event){return _vm.changePage(_vm.page - 1)}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VBtn,{attrs:{"icon":"","disabled":_vm.isLastPage},on:{"click":function($event){return _vm.changePage(_vm.page + 1)}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)]},proxy:true}],null,false,1209202375)}):_c('span',{staticClass:"not-found-text"},[_vm._v(" There are no references to this constant ")])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"d-flex mb-4"},[_c('h3',{staticClass:"text--secondary font-weight-regular header-table"},[_vm._v("Referenced contracts")])])
}]

export { render, staticRenderFns }